import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Skeleton, Tooltip, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { getOrgsByIds } from '../../functions/getOrgsByIds';
import { getTopUserByOrganization } from '../../functions/getTopUserByOrganization';

const columns = [
	{
		field: 'org',
		headerName: 'Organization',
		flex: 1,
		renderCell: (params) => params.row.org || <Skeleton variant='text' width='100%' />, // Adjust width as needed
	},
	{
		field: 'goLive',
		headerName: 'Go Live Date',
		flex: 1,
		renderCell: (params) => params.row.goLive || <Skeleton variant='text' width='100%' />, // Adjust width as needed
	},
	{
		field: 'topUser',
		headerName: 'Top User',
		flex: 1,
		renderCell: (params) => params.row.topUser || <Skeleton variant='text' width='100%' />, // Adjust width as needed
	},
	{
		flex: 1,
		align: 'right',
		renderCell: (params) => (
			<Tooltip title='Open Dashboard' placement='top'>
				<Button
					onClick={() => {
						const child = params.row;
						const childOrgId = child._id;

						const url = child.children
							? `/aggregateDashboard?query=${childOrgId}`
							: `/dashboard?query=${childOrgId}`;
						window.open(url, '_blank');
					}}>
					<OpenInNewIcon />
				</Button>
			</Tooltip>
		),
	},
];

const skeletonRows = Array.from(new Array(12)).map((_, index) => ({
	id: index,
	org: <Skeleton variant='text' width='60%' />,
	goLive: <Skeleton variant='text' width='60%' />,
	topUser: <Skeleton variant='text' width='60%' />,
}));

const Locations = () => {
	const { organization } = useContext(OrganizationContext);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			if (organization?.children?.length > 0) {
				const response = await getOrgsByIds(organization.children, true);
				const rowsData = await Promise.all(
					response.map(async (org, index) => {
						const topUser = await getTopUserByOrganization(org.org);
						return {
							id: index + 1,
							org: org.org,
							goLive: org.goLive ? org.goLive : 'N/A',
							_id: org._id,
							topUser: topUser ? topUser.FIRST + ' ' + topUser.LAST : 'N/A',
						};
					})
				);
				setRows(rowsData);
			}
			setLoading(false);
		}

		fetchData();
	}, [organization]);

	return (
		<Paper elevation={0} sx={{ borderRadius: '1rem', margin: '1rem' }}>
			<Box sx={{ height: '100%', width: '100%' }}>
				<DataGrid
					rows={loading ? skeletonRows : rows}
					columns={columns}
					pageSize={12}
					pageSizeOptions={[12]}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: 12,
							},
						},
						sorting: {
							sortModel: [{ field: 'goLive', sort: 'asc' }],
						},
					}}
					disableSelectionOnClick
					sx={{ borderRadius: '1rem' }}
					components={{
						NoRowsOverlay: () => (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									height: '100%',
								}}>
								<Skeleton variant='rectangular' width='100%' height={118} />
							</Box>
						),
					}}
				/>
			</Box>
		</Paper>
	);
};

export default Locations;
