import React, { useContext, useEffect, useState } from 'react';
import { coversContainer } from './styles';
import { Button, Paper, useTheme } from '@mui/material';
import Actual from './components/Actual/Actual';
import Projected from './components/Projected/Projected';
import HistoricalCovers from './components/HistoricalCovers/HistoricalCovers';
import { UserContext } from '../../../../context/UserContext';
import Outlets from './components/Outlets/Outlets';
import { useLocation } from 'react-router-dom';

const Covers = () => {
	const [currentTab, setCurrentTab] = useState('Historical');
	const theme = useTheme();
	const { user } = useContext(UserContext);
	const location = useLocation();

	const getQueryParams = (query) => {
		return new URLSearchParams(query);
	};

	// Consume orgID from URL query parameters, set organization and user location
	useEffect(() => {
		const fetchData = async () => {
			const queryParams = getQueryParams(location.search);
			const page = queryParams.get('page');

			if (page) {
				setCurrentTab('Actual');
			}
		};
		fetchData();
	}, [location]);

	return (
		<Paper style={coversContainer} sx={{ border: `1px solid ${theme.palette.border.main}` }}>
			<div
				style={{
					// width: '100%',
					display: 'flex',
					flexDirection: 'row',
					color: theme.palette.primary.main,
					gap: '1rem',
					margin: '1rem 1rem 0 1rem',
					border: `1px solid ${theme.palette.border.main}`,
					padding: '0.5rem',
					borderRadius: '0.5rem 0.5rem 0 0',
				}}>
				{(user?.userDept === 'Sales' ||
					user?.userDept === 'Marketing' ||
					user?.userDept === 'Management' ||
					user?.userDept === 'Admin' ||
					user?.userDept === 'Food & Beverage' ||
					user?.canEditCovers === true ||
					user?.organization === 'BioGreen360') && (
					<Button
						sx={{
							width: '100%',

							color: currentTab === 'Outlets' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'Outlets' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: currentTab === 'Outlets' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setCurrentTab('Outlets');
						}}>
						Outlets
					</Button>
				)}

				{(user?.userDept === 'Sales' ||
					user?.userDept === 'Marketing' ||
					user?.userDept === 'Management' ||
					user?.userDept === 'Admin' ||
					user?.userDept === 'Food & Beverage' ||
					user?.canEditCovers === true ||
					user?.organization === 'BioGreen360') && (
					<Button
						onClick={() => {
							setCurrentTab('Projected');
						}}
						sx={{
							width: '100%',

							color: currentTab === 'Projected' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'Projected' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: currentTab === 'Projected' ? '#d5f0e1' : 'transparent',
							},
						}}>
						Add/Update Projected Covers
					</Button>
				)}
				{(user?.userDept === 'Sales' ||
					user?.userDept === 'Marketing' ||
					user?.userDept === 'Management' ||
					user?.userDept === 'Admin' ||
					user?.userDept === 'Food & Beverage' ||
					user?.canEditCovers === true ||
					user?.organization === 'BioGreen360') && (
					<Button
						sx={{
							width: '100%',

							color: currentTab === 'Actual' ? '#58b481' : theme.palette.primary.main,
							backgroundColor: currentTab === 'Actual' ? '#d5f0e1' : 'transparent',
							'&:hover': {
								backgroundColor: currentTab === 'Actual' ? '#d5f0e1' : 'transparent',
							},
						}}
						onClick={() => {
							setCurrentTab('Actual');
						}}>
						Record Actual Covers
					</Button>
				)}

				<Button
					onClick={() => {
						setCurrentTab('Historical');
					}}
					sx={{
						width: '100%',

						color: currentTab === 'Historical' ? '#58b481' : theme.palette.primary.main,
						backgroundColor: currentTab === 'Historical' ? '#d5f0e1' : 'transparent',
						'&:hover': {
							backgroundColor: currentTab === 'Historical' ? '#d5f0e1' : 'transparent',
						},
					}}>
					Historical Covers
				</Button>
			</div>
			<div
				style={{
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					color: '#000',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '1rem',
					margin: '0 1rem 1rem 1rem',
					border: `1px solid ${theme.palette.border.main}`,
					padding: '0.25rem',
					borderRadius: '0 0 0.5rem 0.5rem',
				}}>
				{currentTab === 'Outlets' && <Outlets></Outlets>}
				{currentTab === 'Actual' && <Actual></Actual>}
				{currentTab === 'Projected' && <Projected></Projected>}
				{currentTab === 'Historical' && <HistoricalCovers></HistoricalCovers>}
			</div>
		</Paper>
	);
};

export default Covers;
